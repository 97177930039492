<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
      span="24"
      class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
          $t("grm.specifyAccountNumber")
        }}</info-message>
      </div>
      <van-form>
        <v-text-field
          v-model="accountNumber"
          :placeholder="$t('addAccount.accountNumber')"
          class="mt-10"
        ></v-text-field>

        <itserve-button
          :value="$t('buttons.addAccount')"
          type="primary"
          @click="addKirovogradLs"
          class="mt-15 apply-loader"
        ></itserve-button>
      </van-form>
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
          $t("addAccount.info")
        }}</info-message>
      </div>
      <simple-dialog
        v-model="showDialog"
        :title="addAccountResponses[addAccountMode - 1]"
        :success="addAccountMode === 1"
        @change="goToHomePage"
      />
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import ItserveButton from "@/components/forms/ItserveButton";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  name: "AddGrmLs",
  computed: {
    accountNumber: {
      get() {
        return this.$store.state.Grm.grmKirovograd.kirovogradLs;
      },
      set(accountNumber) {
        this.$store.commit("Grm/SET_NAFTOGAS_LS", this.$route.params.account);
        this.$store.commit("Grm/SET_KIROVOGRAD_LS", accountNumber);
      },
    },
  },
  created() {
    this.$store.commit("Grm/SET_KIROVOGRAD_LS", null);
  },
  data() {
    return {
      showDialog: false,
      showImportDialog: false,
      addAccountMode: 0,
      addAccountResponses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.alreadyAdded"),
        this.$t("addAccount.emptyField"),
      ],
      responses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.alreadyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.oldVersionNotFound"),
      ],
    };
  },
  components: {
    SimpleDialog,
    InfoMessage,
    ItserveButton,
    VanForm: Form,
  },
  methods: {
    goToHomePage() {
      if (this.addAccountMode === 1) {
        this.$router.push({ name: "SendIndications" });
      }
    },
    addKirovogradLs() {
      this.$store.dispatch("Grm/storeNewKirovogradLs", (res) => {
        if (Object.keys(res).length >= 0) {
          this.addAccountMode = res.mode;
          this.showDialog = true;
        } else {
          console.error(
            `Unknown response from server, mode doesn't exists ${JSON.stringify(
              res
            )}`
          );
        }
      });
    },
  },
};
</script>
<style>
.add-account-page-message .info-content,
.import-message .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../../node_modules/vant/lib/button/index.css";
@import "../../../../node_modules/vant/lib/dialog/index.css";
@import "../../../../node_modules/vant/lib/overlay/index.css";
@import "../../../../node_modules/vant/lib/popup/index.css";
</style>
<style>
.v-text-field input {
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  max-width: 337px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
