import { render, staticRenderFns } from "./Shutdowns.vue?vue&type=template&id=f59cc902&scoped=true"
import script from "./Shutdowns.vue?vue&type=script&lang=js"
export * from "./Shutdowns.vue?vue&type=script&lang=js"
import style0 from "./Shutdowns.vue?vue&type=style&index=0&id=f59cc902&prod&lang=css"
import style1 from "./Shutdowns.vue?vue&type=style&index=1&id=f59cc902&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f59cc902",
  null
  
)

export default component.exports