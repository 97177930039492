<template>
  <div class="home-account">
    <div class="place-content-center">
      <div class="icon-container">
        <div class="icon-box apply-loader">
          <home-city fill-color="#1d1d1d" class="account-house-icon-black" />
          <home-city fill-color="#ffffff" class="account-house-icon" />
        </div>
      </div>
      <div class="text-container" v-if="account">
        <ul class="account-data-list">
          <li class="apply-loader">№ {{ account.ls }}</li>
          <li class="apply-loader">{{ account.city }}</li>
          <li class="apply-loader">
            {{ account.streetName }}
            {{ account.building }}
          </li>
          <template v-if="account.appartment">
            <li class="apply-loader">
              {{ $t("components.accounts.apartment") }}
              {{ account.appartment }}
            </li>
          </template>
          <template v-if="showBalance">
            <li class="apply-loader">
              <template v-if="account.balance < 0">
                {{ $t("components.accounts.overpay") }}:
              </template>
              <template v-else>
                {{ $t("components.accounts.debt") }}:
              </template>
              <span
                :class="
                  account.balance === 0
                    ? ''
                    : account.balance > 0
                    ? 'positive-balance'
                    : 'negative-balance'
                "
                ><b>{{ Math.abs(account.balance) }}</b>
              </span>
              {{ $t("components.accounts.currency") }}
            </li>
            <template v-if="account.balance > 0">
              <li class="payment-fee apply-loader">
                <i
                  >{{ $t("components.accounts.feeWillBe") }} {{ getFee() }}
                  {{ $t("components.accounts.currency") }}</i
                >
              </li>
            </template>
          </template>
        </ul>
      </div>
<!--      <template v-if="$route.name === 'Account' && account.balance > 0">-->
<!--        <ul class="paynow-container">-->
<!--          <li class="padding_x">-->
<!--            <itserve-button-->
<!--              :animated="true"-->
<!--              class="mt-15 apply-loader mb-25"-->
<!--              :value="$t('components.accounts.payNow')"-->
<!--              @click="runPayment"-->
<!--            />-->
<!--          </li>-->
<!--        </ul>-->
<!--      </template>-->
      <simple-dialog
        v-model="showDialog"
        :success="true"
        :title="this.$t('pay.fees')"
        @change="runPayment"
      />
    </div>
  </div>
</template>
<script>
import SimpleDialog from "@/components/content/SimpleDialog";
// import ItserveButton from "@/components/forms/ItserveButton";
import HomeCity from "vue-material-design-icons/HomeCity";
export default {
  name: "Account",
  components: { HomeCity,
    SimpleDialog },
  props: {
    account: Object,
    type: {
      type: String,
      default: "regular",
    },
    showBalance: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cards() {
      return this.$store.state.Payment.cards;
    },
  },
  data() {
    return {
      showDialog: false,
      limitStepOne: 300,
      limitStepTwo: 1000,
    };
  },
  methods: {
    getFee() {
      return this.account.balance < this.limitStepOne
        ? 3
        : this.account.balance >= 300 &&
          this.account.balance < this.limitStepTwo
        ? 3
        : this.account.balance > 1000
        ? 3
        : 3;
    },
    showNotification() {
      if (this.cards.length) {
        this.showDialog = true;
      } else {
        this.runPayment();
      }
    },
    async runPayment() {
      await this.$router.push({
        name: "EasyPayPayment",
        params: this.$route.params,
      });
    },
  },
};
</script>

<style scoped>
.payment-fee {
  color: #777777 !important;
  font-size: 14px;
}
.home-account {
  overflow: hidden;
  background-color: #ffffff;
}
.icon-box {
  background-color: #1aad62;
  color: #fff;
  width: 41px;
  height: 41px;
  border-radius: 30px;
  line-height: 52px;
  display: inline-block;
  top: -15px;
  margin-top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 340px) {
  .icon-container {
    width: 30%;
  }
  .text-container {
    width: 70%;
  }
}
@media (min-width: 340px) {
  .icon-container {
    width: 25%;
  }
  .text-container {
    width: 73%;
    margin-left: 2%;
  }
}
.icon-container {
  min-height: 82px;
}
.icon-container,
.text-container {
  position: relative;
  float: left;
}
.text-container {
  color: #0b0b0b;
  text-align: left;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.account-data-list {
  margin-left: 2px;
  font-size: 18px !important;
}
.padding_x {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
