import Grm from "@/plugins/api/factories/Grm";

export const state = {
  grmKirovograd: {
    kirovogradLs: '',
    naftogasLs: '',
  },
  lsInfo: '',
};

export const mutations = {
  SET_KIROVOGRAD_LS(state, kirovogradLs){
    state.grmKirovograd.kirovogradLs = kirovogradLs;
  },
  SET_NAFTOGAS_LS(state, naftogasLs){
    state.grmKirovograd.naftogasLs = naftogasLs;
  },
  SET_LS_INFO(state, lsInfo){
    state.lsInfo = lsInfo;
  }
};

export const getters = {
  getKirovogradLs(){
    return state.grmKirovograd.kirovogradLs;
  }
};

export const actions = {
  storeNewKirovogradLs({ state }, callBackFn) {
    Grm.storeKirovogradLs(state.grmKirovograd, (res) => {
      callBackFn(res);
    });
  },
  getGrmKirovogradLs({ commit }, naftogasLs) {
     Grm.getKirovogradLs({ naftogasLs: naftogasLs },
        (response) => {
          console.log("getGrmKirovogradLs" + JSON.stringify(response))
          commit("SET_LS_INFO", response)
        });
  },
  async sendGrmKirovogradIndications(store, indications) {
    console.log("sendGrmKirovogradIndications" + JSON.stringify(indications))
    const valsToConvert = ["value1"];
    for (const i in valsToConvert) {
      indications[valsToConvert[i]] =
          parseInt(indications[valsToConvert[i]]) || null;
    }
    return Grm.sendGrmKirovogradIndications(indications, (response) => {
      console.log("SENDED INDICATION: " + JSON.stringify(response))
      return response;
    });
  },
};
