import cloneDeep from "lodash.clonedeep";
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
export default {
  components: { ItserveInput, ItserveButton },
  data() {
    return {
      showDialog: false,
      tries: 0,
      dialogMessage: "",
      dialogSuccessMessage: false,
      modeMessages: [
        this.$t("regionalEnergy.sendIndications.successfullySent"),
        this.$t("regionalEnergy.sendIndications.partiallySuccess"),
        this.$t("regionalEnergy.sendIndications.errorDataCreation"),
        this.$t("regionalEnergy.sendIndications.unknownError"),
        this.$t("regionalEnergy.sendIndications.indicationsTooBig"),
        this.$t("regionalEnergy.sendIndications.indicationsTooSmall"),
        this.$t("regionalEnergy.sendIndications.alreadySentToday"),
        this.$t("regionalEnergy.sendIndications.sendLimitation"),
      ],
      indications: {
        value_date: global.getDateCustom("current"),
        ls: this.$route.params.account,
        value1: null,
      },
    };
  },
  computed: {
    photoMode() {
      return this.$store.state.RegionalEnergy.sendPhoto;
    },
    lastIndicators() {
      return this.$store.state.IndicationsHistory.lastIndicators;
    },
    meterageZone() {
      return this.$store.state.RegionalEnergy.meterage_zone;
    },
    errors() {
      return this.$store.state.Api.errors;
    },
    errorMessage() {
      return this.$store.state.Api.errors
          ? this.$store.state.Api.errors.message
          : null;
    },
  },
  methods: {
    goToHomePage() {
      if (this.dialogSuccessMessage === true) {
        this.$router.push({ name: "Account" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async photoSvalueTaken(file) {
      const res = await this.$store.dispatch(
          "RegionalEnergy/detectIndications",
          file
      );
      this.indications.value1 = res.result;
      this.$store.commit("RegionalEnergy/SET_PHOTO_MODE", false);
    },
    async sendIndications() {
      if (this.flashlightEnabled) {
        await this.$store.dispatch("Flashlight/disableFlashlight");
      }
      let indicators = cloneDeep(this.indications);
      for (let objKey in indicators) {
        if (indicators[objKey] === null) {
          indicators[objKey] = null;
        }
      }

      if (indicators.value1 === null || indicators.value1 === undefined) {
        this.showDialog = true;
        this.dialogMessage = this.modeMessages[4];
      } else {
        let res;
        if(this.$store.state.Auth.nativeData.id === 'kirovograd.gas'){
           res = await this.$store.dispatch(
              "Grm/sendGrmKirovogradIndications",
              indicators
          );
        } else {
           res = await this.$store.dispatch(
              "RegionalEnergy/sendIndications",
              indicators
          );
        }

        this.showDialog = false;
        if (res.success === true) {
          this.showDialog = true;
          this.dialogMessage = this.modeMessages[0];
          this.dialogSuccessMessage = true;
        } else {
          this.showDialog = true;
          this.dialogMessage = this.modeMessages[7];
          this.dialogSuccessMessage = false;
        }
      }
    },
  },
};
