<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="40"
      height="40"
      style="margin-top: 5px"
  >
    <!-- Крыша -->
    <polygon points="8,32 32,8 56,32" style="fill:none;stroke:white;stroke-width:2" />

    <!-- Основная структура дома -->
    <rect x="16" y="32" width="32" height="24" style="fill:none;stroke:white;stroke-width:2" />

    <!-- Дверь -->
    <rect x="28" y="44" width="8" height="12" style="fill:none;stroke:white;stroke-width:2" />

    <!-- Окна -->
    <!-- Левое окно -->
    <rect x="20" y="36" width="6" height="6" style="fill:none;stroke:white;stroke-width:2" />
    <line x1="20" y1="39" x2="26" y2="39" style="stroke:white;stroke-width:1" />
    <line x1="23" y1="36" x2="23" y2="42" style="stroke:white;stroke-width:1" />

    <!-- Правое окно -->
    <rect x="38" y="36" width="6" height="6" style="fill:none;stroke:white;stroke-width:2" />
    <line x1="38" y1="39" x2="44" y2="39" style="stroke:white;stroke-width:1" />
    <line x1="41" y1="36" x2="41" y2="42" style="stroke:white;stroke-width:1" />
  </svg>
</template>

<script>
export default {
  name: "utility-icon",
};
</script>

<style scoped></style>
