<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="itserve-center">
        <send-indications-info-part />
        <div
          class="send-indications-content bottom-content-grow flex-display-fix"
        >
          <div class="place-content-center">
            <flashlight />
<!--              <template v-if="windowWidth >= 340">-->
                <single-zone-standart />
<!--              </template>-->
<!--              <template v-else>-->
<!--                <single-zone-small />-->
<!--              </template>-->
          </div>
        </div>
    </van-col>
  </van-row>
</template>

<script>
import "vue-awesome/icons/history";
import "vue-awesome/icons/paper-plane";

import SingleZoneStandart from "@/components/indications/SingleZoneStandart";
import SendIndicationsInfoPart from "@/components/indications/SendIndicationsInfoPart";
import Flashlight from "@/components/indications/Flashlight";
// import SingleZoneSmall from "@/components/indications/SingleZoneSmall";
export default {
  name: "SendIndications",
  components: {
    // SingleZoneSmall,
    Flashlight,
    SendIndicationsInfoPart,
    SingleZoneStandart,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    errorMessage() {
      return this.$store.state.Api.errors.message;
    },
  },
  created() {
    window.addEventListener("resize", this.changeWidthOfWindow);
  },
  destroyed() {
    window.removeEventListener("resize", this.changeWidthOfWindow);
  },
  methods: {
    changeWidthOfWindow() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style scoped>
@import "../../../../node_modules/vant/lib/dialog/index.css";
@import "../../../../node_modules/vant/lib/overlay/index.css";
@import "../../../../node_modules/vant/lib/popup/index.css";
@import "../../../../node_modules/vant/lib/switch/index.css";

.count-zones {
  display: inline-block;
  border: 1px solid #c4c4c4;
  padding: 5px 28px 5px 28px;
  border-radius: 5px;
}
h5 {
  color: #0b0b0b;
  padding: 0;
  text-align: center;
  font-size: 18px;
  margin: 0;
}
</style>
