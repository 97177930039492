<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="mt-25 place-content-center flex-display-fix">
      <send-indications-info-part />
      <info-message class="round-shadow apply-loader add-account-page-message">
        {{ $t("regionalEnergy.sendIndications.sendIndicationInfo") }}
        <p>{{ $t("regionalEnergy.sendIndications.chatBot") }}</p>
      </info-message>
      <div class="array-block">
        <div @click="webLink" class="block">
            <icon :name="web" size="60" />
        </div>
      <div class="block">
          <a href="https://t.me/GASUA_bot">
          <icon :name="telegram" size="60" />
          </a>
        </div>
        <div class="block">
          <a href="viber://pa?chatURI=naftogazpostach">
            <icon :name="viber" size="60" />
          </a>
        </div>
      </div>
      <template v-if="nativeData.id">
      <template v-if="nativeData.id === 'kharkiv.gas'">
        <KharkivGasSendIndication />
      </template>
        <template v-else-if="nativeData.id === 'kirovograd.gas'">
          <template v-if="kirovogradGrmLs.success === true">
            <van-col class="itserve-center mt-10" span="24">
              <itserve-route
                  :buttonName="$t('buttons.grmKirovograd')"
                  route-name="SendIndications"
                  type="wide"
              ></itserve-route>
            </van-col>
          </template>
          <template v-else>
          <AddGrmLs />
          </template>
        </template>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import {Icon} from "vant";
import InfoMessage from "@/components/content/InfoMessage";
import SendIndicationsInfoPart from "@/components/indications/SendIndicationsInfoPart";
import KharkivGasSendIndication from "@/components/contacts/KharkivGasSendIndication";
import AddGrmLs from "@/components/grm/kirovograd/AddGrmLs";
import ItserveRoute from "@/components/content/ItserveRoute";
export default {
  name: "SendIndicationContacts",
  components: {ItserveRoute, AddGrmLs, KharkivGasSendIndication, InfoMessage, Icon, SendIndicationsInfoPart },
  data() {
    return {
      phone: process.env.VUE_APP_CALL_PHONE,
      email: process.env.VUE_APP_SUPPORT_EMAIL,
      phone_view: process.env.VUE_APP_PHONE_VIEW,
    }
  },
  computed: {
    web() {
      return require('@/assets/images/link.png');
    },
    telegram() {
      return require('@/assets/images/free-icon-telegram-2111646.png');
    },
    viber() {
      return require('@/assets/images/free-icon-viber-3938039.png');
    },
    lastIndicators() {
      return this.$store.state.RegionalEnergy.lastIndicators;
    },
    kirovogradGrmLs() {
      return this.$store.state.Grm.lsInfo;
    },
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
  },
  methods: {
    webLink() {
      this.nativeSendEvent("url", "https://gas.ua/uk/indicators");
    }
  },
};
</script>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
h5 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #0b0b0b;
}
.icon {
  --van-cell-icon-size: 40px;
}
.array-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block {
  width: 100px;
  display: inline-block;
  text-align: center;
}
</style>
