import settings from "@/plugins/api/factories/Settings";

export const state = {
  settings: null,
  phone: null,
  newPhone: null,
  agreement: false,
  checkEmail: null,
};

export const mutations = {
  STORE_SETTINGS(state, settings) {
    state.settings = settings;
  },
  STORE_PHONE(state, phone) {
    state.phone = phone;
  },
  STORE_CHECK_EMAIL(state, checkEmail) {
    state.checkEmail = checkEmail;
  },
  UPDATE_LANGUAGE(state, language) {
    if (state.settings !== null) {
      state.settings.language = language;
    }
  },
  UPDATE_NAME(state, name) {
    state.settings.name = name;
  },
  UPDATE_SURNAME(state, surname) {
    state.settings.surname = surname;
  },
  UPDATE_EMAIL(state, email) {
    state.settings.email = email;
  },
  UPDATE_PHONE(state, phone) {
    state.newPhone = phone;
  },
  AGREEMENT(state, agreement) {
    state.agreement = agreement;
  },
};

export const actions = {
  async getPhone({ commit }) {
    await settings.getPhone((response) => {
      commit("STORE_PHONE", response.phone);
    });
  },
  async getSettings({ commit }) {
    settings.getSettings((response) => {
      commit("STORE_CHECK_EMAIL", response.user.email);
      commit("STORE_SETTINGS", response.user);
    });
  },
  async storeSettings({ state }) {
    return await settings.storeSettings(state.settings);
  },
  async storePhone({ state }) {
    return await settings.storePhone(state.newPhone);
  },
  async putLanguage({ commit }, language) {
    commit("UPDATE_LANGUAGE", language);
    return await settings.putLanguage(language);
  },
};
