<template>
  <van-row justify="center" class="page-wrapper prices-page">
    <van-col
      span="24"
      class="itserve-center place-content-center flex-display-fix"
    >
      <info-message class="prices-message round-shadow p-25 pb-25 apply-loader">
        <h3 class="prices-title itserve-center">{{ $t("prices.company") }}</h3>
        <h5 class="prices-title itserve-center">{{ $t("prices.upTo100") }}</h5>
        <van-row class="prices-block">
          <van-col span="12" class="price-text-left">
            {{ $t("prices.withVat") }}
          </van-col>
          <van-col span="12" class="price-text-right"> 7.96 UAH </van-col>
        </van-row>
      </info-message>
    </van-col>
  </van-row>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "Prices",
  components: { InfoMessage },
  data() {
    return {

    };
  },
};
</script>
<style>
.prices-page .info-content {
  width: 100%;
  padding: 0;
}
</style>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
.prices-block div {
  position: relative;
  float: left;
  box-sizing: border-box;
}
.prices-message {
  padding-top: 15px;
  padding-left: 11px;
}
.prices-title {
  color: #2e73b4;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.price-text-left {
  text-align: left;

  padding-top: 10px;
  padding-bottom: 5px;
  color: #0b0b0b;
  font-size: 16px;
}
.price-text-right {
  text-align: right;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: #918dab;
}
</style>
