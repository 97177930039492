<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="mt-25 place-content-center flex-display-fix">
  <info-message class="round-shadow apply-loader add-account-page-message">
    {{ $t("regionalEnergy.sendIndications.call") }}
  </info-message>
  <info-message class="round-shadow apply-loader contacts-list">
    <van-cell
        :value="'+38 (067) 84-75-587'"
        @click="makeACall('+380678475587')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-619'"
        @click="makeACall('+380678475619')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-641'"
        @click="makeACall('+380678475641')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-668'"
        @click="makeACall('+380678475668')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-671'"
        @click="makeACall('+380678475671')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-672'"
        @click="makeACall('+380678475672')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-674'"
        @click="makeACall('+380678475674')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-677'"
        @click="makeACall('+380678475677')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
    <van-cell
        :icon="image"
        :value="'+38 (067) 84-75-678'"
        @click="makeACall('+380678475678')"
    >
      <template #icon>
        <icon :name="image" size="25" />
      </template>
    </van-cell>
  </info-message>
    </van-col>
  </van-row>
</template>
<script>
import {Cell, Icon} from "vant";
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "KharkivGasSendIndication",
  components: { InfoMessage, VanCell: Cell, Icon },
  computed: {
    image() {
      return require('@/assets/images/phoneCallGreen.png');
    },
  },
  methods: {
    makeACall(number) {
      this.nativeSendEvent("tel", { number });
    },
  },
};
</script>
<style>
</style>
<style scoped>
@import "../../../node_modules/vant/lib/cell/index.css";
</style>
