<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        span="24"
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">
          {{ $t("addAccount.street") }}
        </info-message>
      </div>
      <van-form>
        <v-text-field
            :placeholder="$t('addAccount.inputStreet')"
            autocomplete="off"
            v-model="street"
            class="mt-10"
        ></v-text-field>
        <van-col class="itserve-center" span="24">
          <itserve-route
              :buttonName="$t('buttons.addStreet')"
              route-name="ChooseStreet"
              type="wide"
          ></itserve-route>
        </van-col>
      </van-form>
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
// import {VCard, VDivider, VListItem, VListItemContent, VTextField, VVirtualScroll} from "vuetify/lib";
import ItserveRoute from "@/components/content/ItserveRoute";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AddStreet",

  data: () => ({
    street: "",
    allInfo: "",
    listStreet: false,
    showBtn: false,
  }),

  watch: {
    street() {
      this.setStreet(this.street);
    },
  },

  computed: {
    ...mapGetters("ManualAddress", ["STREETS", "SELECTED_CITY"]),
  },

  components: {
    ItserveRoute,
    InfoMessage,
    VanForm: Form,
    // VVirtualScroll,
    // VListItemContent,
    // VListItem,
    // VDivider,
    // VCard,
    // VTextField,
  },

  methods: {
    ...mapActions("ManualAddress", ["getStreetsArr"]),
    ...mapMutations("ManualAddress", ["setStreet"]),

    streetsArr() {
      let data = {
        city: this.SELECTED_CITY,
        street: this.street,
      };
      this.getStreetsArr(data);
    },

    addStreet(street) {
      this.street = street;
    },
    addAll(item) {
      this.allInfo = item;
    }
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
