import axios from "axios";
// import api from "@/plugins/api/EntryPoint";

export const state = {
    city: "",
    street: "",
    cities: [],
    streets: [],
    accounts: [],
    infoAddedAddress: {
        city: "",
        street: "",
        arg_building_num: "",
        arg_building_letter: "",
        arg_corpus_num: "",
        arg_corpus_letter: "",
        arg_flat_num: "",
        arg_flat_letter: "",
    },

};

export const getters = {
    CITIES(state) {
        return state.cities;
    },
    CITY(state) {
        return state.city;
    },
    STREETS(state) {
        return state.streets;
    },
    ACCOUNTS(state) {
        return state.accounts;
    },
    SELECTED_CITY(state) {
        return state.infoAddedAddress.city.citykey;
    },
    SELECTED_STREET(state) {
        return state.infoAddedAddress.street.streetkey;
    },
    SELECTED_INFOADDEDADDRESS(state) {
        return state.infoAddedAddress;
    }
};

export const actions = {
    getCitiesArr(ctx, data) {
        return axios(process.env.VUE_APP_API_ENDPOINT_URL + `accounts/city-search?city=${data.city}`, {
            method: 'GET',
        // headers: {
        //   "Content-Type": "application/json",
        //   "Access-Control-Allow-Headers": "*",
        //     },
        })
            .then((response) => {
                // console.log("getCitiesArr" + JSON.stringify(response.data.city.data))
                ctx.commit('setCities', response.data.city.data)
            }).catch((error)=> {
                console.log(error)
            })
    },

    getStreetsArr(ctx,data) {
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/street-search?street=${data.street}&city=${data.city}`,
            {
                method: "GET",
                // headers: {
                //     "Content-Type": "application/json",
                //     "Access-Control-Allow-Headers": "*",
                // },
            }).then((response) => {
                ctx.commit("setStreets", response.data.street.data);
        }).catch((error) => {
            console.log(error);
        });
    },

    getAccountArr(ctx, data) {
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/account-search?
          street=${data.street}
          &city=${data.city}
          &arg_building_num=${data.arg_building_num}
          &arg_building_letter=${data.arg_building_letter}
          &arg_corpus_num=${data.arg_corpus_num}
          &arg_corpus_letter=${data.arg_corpus_letter}
          &arg_flat_num=${data.arg_flat_num}
          &arg_flat_letter=${data.arg_flat_letter}`,
            {
                method: "GET",
                // headers: {
                //     "Content-Type": "application/json",
                //     "Access-Control-Allow-Headers": "*",
                // },
            }).then((response) => {
            ctx.commit("setAccounts", response.data);
        }).catch((error) => {
            console.log(error);
        });
    },
    async getAccountsArr(ctx, data) {
        console.log("CHLENIX getAccountsArr " + JSON.stringify(data))
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/ls-search?
          street=${data.street}
          &city=${data.city}
          &building=${data.building}
          &apartment=${data.apartment}`,
            {
                method: "GET",
                // headers: {
                //     "Content-Type": "application/json",
                // },
            }).then((response) => {
            ctx.commit("setAccounts", response.data);
            console.log("CHLENIX setAccounts " + JSON.stringify(response.data))
        }).catch((error) => {
            console.log(error);
        });
    },

};

export const mutations = {
    setCities(state, value) {
        state.cities = value;
    },
    setStreets(state, value) {
        state.streets = value;
    },
    setAccounts(state, value) {
        state.accounts = value;
    },
    setCity(state, city) {
        state.city = city
    },
    setStreet(state, street) {
        state.street = street
    },
    setArgBuildingNum(state, value) {
        state.infoAddedAddress.arg_building_num = value;
    },
    setArgBuildingLetter(state, value) {
        state.infoAddedAddress.arg_building_letter = value;
    },
    setArgCorpusNum(state, value) {
        state.infoAddedAddress.arg_corpus_num = value;
    },
    setArgCorpusLetter(state, value) {
        state.infoAddedAddress.arg_corpus_letter = value;
    },
    setArgFlatNum(state, value) {
        state.infoAddedAddress.arg_flat_num = value;
    },
    setArgFlatLetter(state, value) {
        state.infoAddedAddress.arg_flat_letter = value;
    },




    setInfoAddedAddress(state, data) {
        if (data.all) {
            state.infoAddedAddress.city = data.city;
            state.infoAddedAddress.street = data.street;
        } else if (data.street) {
            state.infoAddedAddress.street = data.street;
        } else if (data.city) {
            state.infoAddedAddress.city = data.city;
        }
    },
};
