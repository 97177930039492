<template>
  <van-row style="z-index: 9999999999" class="page-wraper payment-page">
    <loader :show="loading" />
    <van-col class="flex-display-fix" span="24">
      <div class="iframe-container">
        <iframe
          frameborder="0"
          :src="easyPayAllpaymentUrl"
          class="iframe"
          ref="payPage"
          @load="handleLoad"
        ></iframe>
      </div>
    </van-col>
  </van-row>
</template>
<script>
import Loader from "@/components/content/Loader";
export default {
  components: {
    Loader,
  },
  name: "EasyPayAllServicePage",
  computed: {
    getPlatform() {
      return this.$store.state.Auth.nativeData.platform;
    },
    easyPayAllpaymentUrl() {
      console.log("UTILITY: " + this.$route.params.easyPayUrl);
      return this.$route.params.easyPayUrl;
    },
  },
  data() {
    return {
      watchInterval: null,
      showDialog: false,
      loading: true,
    };
  },
  created() {
    if (this.getPlatform !== "ios") {
      this.nativeSendEvent("url", this.$route.params.easyPayUrl);
    }
  },
  destroyed() {
    clearInterval(this.watchInterval);
  },
  methods: {
    handleLoad() {
      this.loading = false;
    },
  },
};
</script>

<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
.iframe {
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  height: 100vh;
  min-width: 100%;
}
</style>
