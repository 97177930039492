import api from "@/plugins/api/EntryPoint";
export default {
    async storeKirovogradLs(grmKirovograd, callBackFn) {
        await api.post(`grm/kirovograd-ls`, {kirovogradLs: grmKirovograd.kirovogradLs, naftogasLs: grmKirovograd.naftogasLs }, callBackFn);
    },
    async getKirovogradLs(params, callBackFn) {
        await api.post(`grm/get-kirovograd-ls`, params, callBackFn);
    },
    async sendGrmKirovogradIndications(indications, callBackFn) {
        return api.post("grm/input-kirovograd-counter", indications, callBackFn);
    },
};
