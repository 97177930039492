<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        span="24"
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <template v-if="this.ACCOUNTS.success === true">
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.lsNumber")
          }}
        </info-message>
      </div>
      <van-form>
        <v-text-field
            :placeholder="$t('addAccount.lsNumber')"
            autocomplete="off"
            v-model="accountNumber"
            class="mt-10"
        ></v-text-field>
        <v-card v-if="listAccounts">
          <v-virtual-scroll
              height="200"
              item-height="64"
              :items="ACCOUNTS.account"
          >
            <template v-slot="{ item }">
              <v-list-item :key="item.index">
                <v-list-item-content @click="addAccount(item.peracc)">
                  {{ item.peracc }}
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
      </van-form>
      </template>
      <template v-else>
        <div class="place-content-center">
          <info-message class="apply-loader add-account-page-message">
            {{this.accountAddress}}
            <p>{{ $t("addAccount.wrong") }}</p>
          </info-message>
        </div>
      </template>
      <van-col class="itserve-center" span="24">
        <itserve-button
            :value="$t('buttons.addAccount')"
            type="primary"
            @click="addAccountNumber"
            class="mt-15 apply-loader"
        ></itserve-button>
      </van-col>
      <simple-dialog
          v-model="showDialog"
          :title="addAccountResponses[addAccountMode - 1]"
          :success="addAccountMode === 1"
          @change="goToHomePage"
      />
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import ItserveButton from "@/components/forms/ItserveButton";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
import SimpleDialog from "@/components/content/SimpleDialog";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AddLs",
  data() {
    return {
      listAccounts: true,
      showBtn: false,
      showDialog: false,
      mode: 0,
      addAccountMode: 0,
      addAccountResponses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.alreadyAdded"),
      ],
      responses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.alreadyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.oldVersionNotFound"),
      ],
    };
  },

  watch: {
    accountNumber() {
      this.showBtn = true;
    }
  },

  computed: {
    ...mapGetters("ManualAddress", ["ACCOUNTS", "SELECTED_INFOADDEDADDRESS"]),
    accountNumber: {
      get() {
        return this.$store.state.Auth.accountNumber;
      },
      set(accountNumber) {
        this.$store.commit("Auth/SET_ACCOUNT_NUMBER", accountNumber);
      },
    },
    accountAddress() {
      let address;
      let city = this.SELECTED_INFOADDEDADDRESS.city.citytype + ' ' + this.SELECTED_INFOADDEDADDRESS.city.name;
      let district =  this.SELECTED_INFOADDEDADDRESS.city.district + ' район';
      let region = this.SELECTED_INFOADDEDADDRESS.city.region;
      let addrName =  this.SELECTED_INFOADDEDADDRESS.street.name + ' ' + this.SELECTED_INFOADDEDADDRESS.street.streettype;

      let buildNum;
      if(this.SELECTED_INFOADDEDADDRESS.arg_building_letter.length > 0) {
        buildNum = this.SELECTED_INFOADDEDADDRESS.arg_building_num + ' ' + this.SELECTED_INFOADDEDADDRESS.arg_building_letter;
      } else {
        buildNum = this.SELECTED_INFOADDEDADDRESS.arg_building_num
      }

      let corpusNum;
      if(this.SELECTED_INFOADDEDADDRESS.arg_corpus_letter.length > 0) {
        corpusNum = this.SELECTED_INFOADDEDADDRESS.arg_corpus_num + ' ' + this.SELECTED_INFOADDEDADDRESS.arg_corpus_letter;
      } else {
        corpusNum = this.SELECTED_INFOADDEDADDRESS.arg_corpus_num
      }

      let flatNum;
      if(this.SELECTED_INFOADDEDADDRESS.arg_flat_letter.length > 0) {
        flatNum = this.SELECTED_INFOADDEDADDRESS.arg_flat_num + ' ' + this.SELECTED_INFOADDEDADDRESS.arg_flat_letter;
      } else {
        flatNum = this.SELECTED_INFOADDEDADDRESS.arg_flat_num
      }



      if(this.SELECTED_INFOADDEDADDRESS.arg_corpus_num.length > 0 && this.SELECTED_INFOADDEDADDRESS.arg_flat_num.length > 0){
        address = addrName  + ' ' + buildNum + ', корпус ' + corpusNum + ', квартира ' + flatNum + ', ' + city + ', ' + district + ', ' + region;
      } else if(this.SELECTED_INFOADDEDADDRESS.arg_flat_num.length > 0) {
        address = addrName  + ' ' + buildNum + ', квартира ' + flatNum + ', ' + city + ', ' + district + ', ' + region;
      } else if(this.SELECTED_INFOADDEDADDRESS.arg_corpus_num.length > 0) {
        address = addrName  + ' ' + buildNum + ', корпус ' + corpusNum + ', ' + city + ', ' + district + ', ' + region;
      } else {
        address = addrName + ' ' + buildNum + ', ' + city + ', ' + district + ', ' + region
      }

      return address;
    }
  },

  components: {
    SimpleDialog,
    InfoMessage,
    ItserveButton,
    VanForm: Form,
  },

  methods: {
    ...mapActions("ManualAddress", ["getAccountArr"]),
    addAccountNumber() {
      this.$store.dispatch("Auth/storeNewAccount", (res) => {
        if (Object.keys(res).indexOf("mode") >= 0) {
          this.addAccountMode = res.mode;
          this.showDialog = true;
        } else {
          console.error(`Unknown response from server, mode doesn't exists ${JSON.stringify(res)}`
          );
        }
      });
    },
    addAccount(accountNumber) {
      this.accountNumber = accountNumber;
      this.listAccounts = false;
    },
    goToHomePage() {
      if (this.addAccountMode === 1) {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
<style>
.v-text-field {
  margin-right: 10%;
  margin-left: 10%;
}

.v-text-field input {
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
}
</style>
