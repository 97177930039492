import api from "@/plugins/api/EntryPoint";
import axios from "axios";

export default {
  /**
   * Retrieve accounst from server before return account prepare them for displaying
   * few resions to prepare data - right variable names, format words
   * @param callBackFn
   * @returns {Promise<*>}
   */
  getAccounts(callBackFn) {
    return api.get("settings/get-accountsapp", (response) => {
      let accountsData = {};
      for (let i = 0; i < response.accounts.length; i++) {
        let account = response.accounts[i];
        accountsData[account.ls] = this.prepareAccount(account);
      }

      callBackFn(accountsData);
    });
  },
  async importAccounts(callBackFn) {
    return api.get("ls/import-old", callBackFn);
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  prepareAccount(account) {

    console.log("ACCOUNT GET LS: " + JSON.stringify(account))

    let build
    let apart

    if(account.buildlitter.length > 0) {

      build = account.buildnum + "/" + account.buildlitter;

    } else {

      build = account.buildnum;

    }

    if(account.apartmentlitter.length > 0) {

      apart = account.apartmentnum + "-" + account.apartmentlitter;

    } else {

      apart = account.apartmentnum;

    }

    return {
      accountNumber: account.ls,
      building: build,
      streetName: account.streetname
          ? this.capitalizeFirstLetter(account.streetname.toLowerCase())
          : "",
      appartment: apart === "" ? null : apart,
      balance: account.saldo ? account.saldo : 0,
      ls: account.ls,
    };
  },
  getAccount(accountNumber, callBackFn) {
    return api.get(`balance/fullSearchNew?lic=${accountNumber}`, (response) => {
      //response.balance.ls = response.balance.OS_RAH;
      let accountData = this.prepareAccount(response.balance);
      callBackFn(accountData);
    });
  },
  async refreshToken(cb) {
    return await api.get(`auth/refresh-token`, (response) => {
      return cb(response);
    });
  },
  storeAccount(accountNumber, cb) {
    return api.post("accounts/add-account-new", { ls: accountNumber }, cb);
  },
  storeAccountEic(accountNumber, cb) {
    return api.post("accounts/add-eic-new", { eic: accountNumber }, cb);
  },
  async nativeAuth(nativeData) {
    console.log("CHLENIX FOR BACK: " + JSON.stringify(nativeData));
    return await axios
        .post(
            process.env.VUE_APP_API_ENDPOINT_URL + "userAuth",
            nativeData
        )
        .catch((error) => {
          console.log(error);
        });
  },
  async googleAuth(nativeData) {
    console.log("CHLENIX FOR BACK googleAuth: " + JSON.stringify(nativeData));
    return await axios
        .post(
            process.env.VUE_APP_API_ENDPOINT_URL + "googleAuth",
            nativeData
        )
        .catch((error) => {
          console.log(error);
        });
  },
  async appleAuth(nativeData) {
    console.log("CHLENIX FOR BACK appleAuth: " + JSON.stringify(nativeData));
    return await axios
        .post(
            process.env.VUE_APP_API_ENDPOINT_URL + "appleAuth",
            nativeData
        )
        .catch((error) => {
          console.log(error);
        });
  },
  deleteAccountByNumber(accountNumber, callBackFn) {
    return api.post(
        "settings/delete-account",
        { ls: accountNumber },
        callBackFn
    );
  },
  deleteUser(cb) {
    return api.post(
        "settings/delete-user", cb
    );
  },
  guestMode(guest, cb) {
    return api.put("settings/set-guest", { guest: guest }, cb);
  },
  getGuestMode(callBackFn) {
    return api.get("settings/guest", callBackFn);
  },
  getSubscriptionStatus(callBackFn) {
    return api.get("settings/get-user-status", callBackFn)
  },
  getStripeStatus(callBackFn) {
    return api.get("settings/get-stripe-status", callBackFn)
  }
};
