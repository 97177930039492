<template>
  <van-row justify="center" class="page-wrapper">
    <van-col
        span="24"
        class="
        round-shadow-btn
        flex-display-fix
        container_width
        mt-15
      "
    >
      <div style="text-align: center; font-size: 48px" class="apply-loader itserve-info-message-new round-shadow-btn-new">
    <span class="info-content-new">
      <slot>{{ $t("pay.statusCredit") }}</slot>
    </span>
      </div>
      <div style="margin-top: -15px" class="apply-loader itserve-info-message-new round-shadow-btn-new-new">
    <span class="info-content-new">
      <slot>{{ $t("pay.statusMessageNew") }}</slot>
    </span>
      </div>
    </van-col>
    <van-col span="24" class="itserve-center flex-display-fix">
      <itserve-route
          route-name="StripePayment"
          :buttonName="$t('settings.stripeButton')"
          type="wide"
          class="less-tall-btn mb-25 mt-25"
      ></itserve-route>
    </van-col>
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
export default {
  name: "PayAllNew",
  components: { ItserveRoute },
  computed: {

  },
  data() {
    return {
      paymentUrl: null,
    };
  },
  created() {
    // this.getLinkToAddCard();
  },
  destroyed() {
    clearInterval(this.watchInterval);
  },
  methods: {
    getLinkToAddCard() {
      let url = '';
      let id = this.$store.state.Auth.nativeData.id;
      switch (id) {
        case 'zaporizhzhia.gas':
          url = ''
          break;
        case 'lviv.gas':
          url = 'https://easypay.ua/ua/catalog/utility/lvov/communal-lvov-merger'
          break;
        case 'volyn.gas':
          url = 'https://easypay.ua/ua/catalog/utility/lutsk/mistsevyyobchysltsentr-fam'
          break;
        case 'kherson.gas':
          url = ''
          break;
        case 'ternopil.gas':
          url = 'https://easypay.ua/ua/catalog/utility/ternopol/ternopil-utilities/unified-receipt-ternopil-adress'
          break;
        case 'chernivtsi.gas':
          url = 'https://easypay.ua/ua/catalog/utility/chernivtsi/communal-chernivtsi-ss'
          break;
        case 'prykarpattya.gas':
          url = ''
          break;
        case 'zakarpattya.gas':
          url = ''
          break;
        case 'rivne.gas':
          url = ''
          break;
        case 'khmelnytskyi.gas':
          url = ''
          break;
        case 'vinnytsia.gas':
          url = 'https://easypay.ua/ua/catalog/utility/vinnica/mps-vinnitsa'
          break;
        case 'cherkasy.gas':
          url = ''
          break;
        case 'chernihiv.gas':
          url = ''
          break;
        case 'mykolaiv.gas':
          url = ''
          break;
        case 'dnipro.gas':
          url = 'https://easypay.ua/ua/catalog/utility/dnepropetrovsk/kp-erc-dnipro'
          break;
        case 'poltava.gas':
          url = ''
          break;
        case 'odesa.gas':
          url = 'https://easypay.ua/ua/catalog/utility/odessa/kvartplata_odessa_communal/gioc-address-odessa'
          break;
        case 'zhytomyr.gas':
          url = ''
          break;
        case 'kirovograd.gas':
          url = ''
          break;
        case 'sumy.gas':
          url = ''
          break;
        case 'kyiv.gas':
          url = 'https://easypay.ua/ua/catalog/utility/kiev/communal-kyiv-1/communal-kyiv-address'
          break;
        case 'kharkiv.gas':
          url = 'https://easypay.ua/ua/catalog/utility/harkov/erc-communal-term'
          break;
        default:
          url = ''
      }
      this.nativeSendEvent("url", url);
    },
  },
};
</script>

<style scoped>
.iframe-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  height: calc(100vh - 120px);
}
.iframe-container iframe {
  height: calc(100vh - 120px);
  width: 1px;
  min-width: 100%;
  *width: 100%;
}
.add-card-page {
  border: none !important;
}
.add-card-page-wraper {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
.write-ticket-message .info-content {
  text-align: justify!important;
}
.itserve-info-message-new {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0b0b0b;
  font-size: 18px;
  text-align: justify;
  max-width: 337px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.info-content-new {
  max-width: 317px;
  display: inline-block;
  text-align: justify;
  padding: 10px;
  white-space: pre-line;
}
.app-theme-dark .round-shadow-btn-new{
  border-radius: 15px 15px 0 0;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  padding-bottom: 15px;
  background-color: #474545 !important;
  box-shadow: none !important;
  color: #fff;
}
.app-theme-dark .round-shadow-btn-new-new{
  border-radius: 0 0 15px 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  padding-bottom: 15px;
  background-color: #474545 !important;
  box-shadow: none !important;
  color: #fff;
}
</style>
