<template>
  <van-row class="page-wrapper flex-display-fix" justify="center">
    <van-col class="itserve-center place-content-center" span="24">
      <h5 class="page-type apply-loader">
        {{ $t("regionalEnergy.index.title") }}
      </h5>
      <itserve-route
        route-name="SendIndicationContacts"
        :buttonName="$t('buttons.sendIndications')"
        :params="$route.params"
        type="wide"
        class="nav-btn mt-15"
        :icon="true"
        icon-color="#3C2C93"
        btn-style="box"
      >
        <send-indications-icon2-box />
      </itserve-route>

      <!--      <itserve-route-->
      <!--        route-name="SendIndicationsPhotoMainMenu"-->
      <!--        :buttonName="$t('buttons.sendIndicationsPhoto')"-->
      <!--        :params="$route.params"-->
      <!--        type="wide"-->
      <!--        class="nav-btn mt-15 fr"-->
      <!--        :icon="true"-->
      <!--        icon-color="#3C2C93"-->
      <!--        btn-style="box"-->
      <!--      >-->
      <!--        <send-indications-icon2-box />-->
      <!--      </itserve-route>-->

      <!--    route  IndicationsHistory-->
      <itserve-route
        route-name="IndicatorsHistorySearchResults"
        :buttonName="$t('buttons.indicationsHistory')"
        :params="$route.params"
        type="wide"
        class="nav-btn mt-15 mb-25 fr"
        :icon="true"
        icon-color="#1AAD62"
        btn-style="box"
      >
        <indications-history-icon-box />
      </itserve-route>
      <!--      <itserve-route-->
      <!--        route-name="ContactRegionalEnergy"-->
      <!--        :buttonName="$t('buttons.regionalEnergyContacts')"-->
      <!--        type="wide"-->
      <!--        class="nav-btn mt-15 fr mb-25"-->
      <!--        :icon="true"-->
      <!--        icon-color="#FF8573"-->
      <!--        btn-style="box"-->
      <!--      >-->
      <!--        <contacts-icon-box />-->
      <!--      </itserve-route>-->
    </van-col>
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
import "vue-awesome/icons/box";
import SendIndicationsIcon2Box from "@/components/icons/send-indications-icon2-box";
import IndicationsHistoryIconBox from "@/components/icons/indications-history-icon-box";
// import ContactsIconBox from "@/components/icons/contacts-icon-box";
export default {
  name: "RegionalEnergy",
  components: {
    // ContactsIconBox,
    IndicationsHistoryIconBox,
    SendIndicationsIcon2Box,
    ItserveRoute,
  },
  created() {
    this.getLastIndication();
    this.getKirovogradGrmLs();
  },
  methods: {
    async getLastIndication() {
      await this.$store.dispatch(
        "IndicationsHistory/getLastIndications",
        this.$route.params.account
      );
    },
    async getKirovogradGrmLs() {
      await this.$store.dispatch(
        "Grm/getGrmKirovogradLs",
        this.$route.params.account
      );
    },
  },
};
</script>
<style scoped>
.page-type {
  padding: 0;
  font-size: 18px;
  margin: 25px 0 0 0;
  color: #0b0b0b;
  text-align: center;
  width: 100%;
}
</style>
