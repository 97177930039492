<template>
  <van-row class="page-wraper payment-page">
    <loader :show="showLoader" />
    <template v-if="paymentUrl">
      <van-col class="flex-display-fix" span="24">
        <div class="iframe-container">
          <iframe
              :src="paymentUrl"
              allowpaymentrequest
              frameborder="0"
              class="payment-page"
              ref="payPage"
          ></iframe>
        </div>
      </van-col>
    </template>
    <template v-else>
      <template v-if="cards.length">
        <van-col class="round-shadow-btn mb-25" span="24">
          <info-message class="itserve-center place-content-center">{{
              $t("paymentPage.chooseCard")
            }}</info-message>
        </van-col>
        <van-col span="24">
          <template v-for="card in cards">
            <van-cell
                class="
                cards-list-cell
                shadow-white
                mb-25
                single-card
                place-content-center
              "
                :title="card.pan"
                :key="card.pan"
                value=""
                @click="chooseCard(card.card_token)"
            >
              <template #icon>
                <template v-if="card.pan.charAt(0) === '4'">
                  <img src="@/assets/images/visa.png" class="card-type-image" />
                </template>
                <template v-else>
                  <img
                      src="@/assets/images/mastercard.png"
                      class="card-type-image"
                  />
                </template>
              </template>
            </van-cell>
          </template>
        </van-col>
        <van-col span="24" class="itserve-center">
          <itserve-route
              type="primary"
              :buttonName="$t('pay.addCard')"
              class="mt-15"
              route-name="AddCard"
          />
        </van-col>
      </template>
    </template>
    <simple-dialog
        v-model="showDialog"
        :success="true"
        :title="this.$t('paymentPage.phoneTitle')"
    />
  </van-row>
</template>
<script>
// import Dialog from "vant/lib/dialog/index";
import Cell from "vant/lib/cell/index";
import InfoMessage from "@/components/content/InfoMessage";
import Loader from "@/components/content/Loader";
import ItserveRoute from "@/components/content/ItserveRoute";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  components: {
    ItserveRoute,
    Loader,
    InfoMessage,
    VanCell: Cell,
    SimpleDialog,
  },
  name: "PaymentPage",
  computed: {
    getPlatform() {
      return this.$store.state.Auth.nativeData.platform;
    },
    getPhone() {
      return this.$store.state.Settings.phone;
    },
    showLoader() {
      return this.$store.state.Api.loading;
    },
    paymentData() {
      return this.$store.state.Payment.paymentData;
    },
    paymentResponse() {
      return this.$store.state.Payment.paymentResponse;
    },
    paymentUrl() {
      return this.$store.state.Payment.paymentUrl;
    },
    account() {
      return this.$store.state.Auth.account;
    },
    cards() {
      return this.$store.state.Payment.cards;
    },
  },
  data() {
    return {
      watchInterval: null,
      showDialog: false,
    };
  },
  created() {
    this.$store.dispatch("Settings/getPhone");
    this.$store.commit("Payment/SET_PAYMENT_URL", null);
    this.runCheck();
    this.saveCard();
  },
  destroyed() {
    clearInterval(this.watchInterval);
  },
  methods: {
    async chooseCard(cardToken) {
      await this.$store.commit("Payment/SET_CARD_TOKEN", cardToken);
      await this.preparePaymentPage();
    },
    async saveCard() {
      await this.$store.commit("Payment/SET_CARD_TOKEN", null);
      await this.$store.dispatch("Payment/getCardsList");
      if (!this.cards.length) {
        // Dialog.confirm({
        //   title: this.$t("paymentPage.saveTitle"),
        //   message: this.$t("paymentPage.saveCard"),
        //   cancelButtonText: this.$t("paymentPage.saveNo"),
        //   confirmButtonText: this.$t("paymentPage.saveYes"),
        // })
        //     .then(async () => {
        //       if (this.getPhone === null) {
        //         this.showDialog = true;
        //       }
        //       await this.$store.commit("Payment/SET_SAVE_CARD", true);
              await this.preparePaymentPage();
            // })
            // .catch(async () => {
            //   await this.$store.commit("Payment/SET_SAVE_CARD", false);
            //   await this.preparePaymentPage();
            // });
      }
      /*if (this.cards.length === 1) {
        await this.$store.commit("Payment/SET_SAVE_CARD", false);
        await this.$store.commit(
          "Payment/SET_CARD_TOKEN",
          this.cards[0].card_token
        );
        await this.preparePaymentPage();
      }*/
    },
    runCheck() {
      this.watchInterval = setInterval(() => {
        if (this.$refs.payPage !== undefined) {
          try {
            let url = this.$refs.payPage.contentWindow.location.href;
            if (url.match(process.env.VUE_APP_PRODUCTION_URL) !== null) {
              clearInterval(this.watchInterval);
              this.$router.push(
                  url.replace(process.env.VUE_APP_PRODUCTION_URL, "")
              );
            }
          } catch (e) {
            console.log("Waiting");
          }
        }
      }, 200);
    },
    async preparePaymentPage() {
      if (this.account === null) {
        await this.$store.dispatch(
            "Auth/getUserAccount",
            this.$route.params.account
        );
        await this.runPaymentRequest();
      } else {
        await this.runPaymentRequest();
      }
    },
    async runPaymentRequest() {
      await this.$store.commit(
          "Payment/SET_USER_LS",
          this.$route.params.account
      );
      try {
        await this.$store.dispatch("Payment/getPaymentPage");
        if (this.getPlatform !== "ios") {
          if (this.paymentUrl) {
            this.nativeSendEvent("url", this.paymentUrl);
          } else {
            console.log("Payment URL is not available");
          }
        }
        if (Object.keys(this.paymentResponse).indexOf("mode") >= 0) {
          if (this.paymentResponse.mode === 1) {
            await this.$router.push({ name: "SuccessfullyPaid" });
          } else {
            await this.$router.push({ name: "CancelPayment" });
          }
        }
      } catch (e) {
        await this.$router.push({ name: "CancelPayment" });
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
.iframe-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  height: calc(100vh - 120px);
}
.iframe-container iframe {
  height: calc(100vh - 120px);
  width: 1px;
  min-width: 100%;
  *width: 100%;
}
.cards-list {
  padding-top: 25px;
}
.cards-list-cell {
  font-size: 18px !important;
}
.card-type-image {
  height: 20px;
  margin-right: 5px;
}
.payment-page {
  width: 100%;
  flex-grow: 1;
  border: none !important;
}
</style>
