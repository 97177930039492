import indicationsHistory from "@/plugins/api/factories/IndicationsHistory";

export const state = {
  lastIndicators: {},
  indicators: {},
  filterValues: null,
};

export const actions = {
  async getLastIndications({ commit }, ls) {
    await indicationsHistory.getLastIndications({ ls: ls },
        (response) => {
          if(response.error) {
            commit("SET_LAST_INDICATORS", response.error);
            commit("SET_INDICATORS_HISTORY", response.error);
          }
          commit("SET_LAST_INDICATORS", response.indications[0]);
          commit("SET_INDICATORS_HISTORY", response.indications);
        });
  },
};

export const mutations = {
  SET_LAST_INDICATORS(state, lastIndicators) {
    state.lastIndicators = lastIndicators;
  },
  SET_INDICATORS_HISTORY(state, indicators) {
    state.indicators = indicators;
  },
  SET_FILTER_VALUES(state, filterValues) {
    state.filterValues = filterValues;
  },
};
