<template>
  <van-row justify="center" class="page-wrapper">
    <van-col
        span="24"
        class="place-content-center round-shadow-btn flex-display-fix"
    >
      <template v-if="paymentHistory">
        <template v-for="message in paymentHistory">
          <info-message
              :key="'msg_' + message.last_date_month"
              class="history-item"
          >
            <van-row>
              <h5 class="energy-title apply-loader">
                {{ $t("paymentHistorySearch.energyTitle") }}
              </h5>
              <!--              <p class="payment-type">-->
              <!--                {{ message.pay }}-->
              <!--              </p>-->
              <!--              <van-col span="12" class="payment-history-left apply-loader">-->
              <!--                {{ $t("paymentHistorySearch.period") }}-->
              <!--              </van-col>-->
              <!--              <van-col span="12" class="payment-history-right apply-loader">-->
              <!--                {{ message.period }}-->
              <!--              </van-col>-->

              <van-col span="12" class="payment-history-left apply-loader">
                {{ $t("paymentHistorySearch.period") }}
              </van-col>
              <van-col span="12" class="payment-history-right apply-loader">
                {{ message.last_date_month }}
              </van-col>
              <van-col span="12" class="payment-history-left apply-loader">
                {{ $t("paymentHistorySearch.sum") }}
              </van-col>
              <van-col span="12" class="payment-history-right apply-loader">
                {{ message.amount_payments + " грн" }}
              </van-col>
            </van-row>
          </info-message>
        </template>
      </template>
      <template v-else>
        <div class="place-content-center">
          <info-message>
            {{ $t("global.noData") }}
          </info-message>
        </div>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "PaymentHistorySearchResults",
  components: { InfoMessage },
  computed: {
    paymentHistory() {
      return this.$store.state.PaymentHistory.paymentHistory;
    },
  },
};
</script>
<style scoped>
.payment-type {
  font-size: 12px;
  margin: 5px 5px 0;
}
.energy-title {
  color: #2e73b4;
  font-size: 18px;
  margin: 5px 5px 0;
}
.payment-history-left,
.payment-history-right {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0b0b0b;
}
.payment-history-right {
  text-align: right;
  padding-right: 15px;
}
.payment-history-left {
  text-align: left;
  padding-left: 15px;
}
</style>
