<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        span="24"
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">
          {{ $t("addAccount.addressInfo") }}
          <p>{{ $t("addAccount.addressInfoBuild") }}</p>
          <p>{{ $t("addAccount.addressInfoCorpus") }}</p>
          <p>{{ $t("addAccount.addressInfoFlat") }}</p>
        </info-message>
      </div>
        <van-form>
          <itserve-input
              v-model="arg_building_num"
              type="text"
              class="apply-loader"
              :placeholder="$t('addAccount.arg_building_num')"
              name="name"
          />
          <itserve-input
              v-model="arg_building_letter"
              type="text"
              class="apply-loader"
              :placeholder="$t('addAccount.arg_building_letter')"
              name="surname"
          />
          <itserve-input
              v-model="arg_corpus_num"
              type="text"
              class="apply-loader"
              :placeholder="$t('addAccount.arg_corpus_num')"
              name="email"
          />
          <itserve-input
              v-model="arg_corpus_letter"
              type="text"
              class="apply-loader"
              :placeholder="$t('addAccount.arg_corpus_letter')"
              name="email"
          />
          <itserve-input
              v-model="arg_flat_num"
              type="text"
              class="apply-loader"
              :placeholder="$t('addAccount.arg_flat_num')"
              name="email"
          />
          <itserve-input
              v-model="arg_flat_letter"
              type="text"
              class="apply-loader"
              :placeholder="$t('addAccount.arg_flat_letter')"
              name="email"
          />
        </van-form>
      <van-col class="itserve-center" span="24">
        <itserve-button
            :value="$t('buttons.saveChanges')"
            type="primary"
            @click="addInfo"
            class="mt-15 apply-loader"
        ></itserve-button>
      </van-col>
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
// import ItserveRoute from "@/components/content/ItserveRoute";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";

export default {
  name: "AddressInfoInput",
  data: () => ({
    listAccount: false,
    showBtn: false,
  }),
  computed: {
    ...mapGetters("ManualAddress", [
      "SELECTED_CITY",
      "SELECTED_STREET",
        "ACCOUNTS"
    ]),
    arg_building_num: {
      get() {
        return this.$store.state.ManualAddress.infoAddedAddress.arg_building_num;
      },
      set(arg_building_num) {
        this.$store.commit("ManualAddress/setArgBuildingNum", arg_building_num);
      },
    },
    arg_building_letter: {
      get() {
        return this.$store.state.ManualAddress.infoAddedAddress.arg_building_letter;
      },
      set(arg_building_letter) {
        this.$store.commit("ManualAddress/setArgBuildingLetter", arg_building_letter);
      },
    },
    arg_corpus_num: {
      get() {
        return this.$store.state.ManualAddress.infoAddedAddress.arg_corpus_num;
      },
      set(arg_corpus_num) {
        this.$store.commit("ManualAddress/setArgCorpusNum", arg_corpus_num);
      },
    },
    arg_corpus_letter: {
      get() {
        return this.$store.state.ManualAddress.infoAddedAddress.arg_corpus_letter;
      },
      set(arg_corpus_letter) {
        this.$store.commit("ManualAddress/setArgCorpusLetter", arg_corpus_letter);
      },
    },
    arg_flat_num: {
      get() {
        return this.$store.state.ManualAddress.infoAddedAddress.arg_flat_num;
      },
      set(arg_flat_num) {
        this.$store.commit("ManualAddress/setArgFlatNum", arg_flat_num);
      },
    },
    arg_flat_letter: {
      get() {
        return this.$store.state.ManualAddress.infoAddedAddress.arg_flat_letter;
      },
      set(arg_flat_letter) {
        this.$store.commit("ManualAddress/setArgFlatLetter", arg_flat_letter);
      },
    },
  },

  components: {
    ItserveButton,
    ItserveInput,
    // ItserveRoute,
    InfoMessage,
    VanForm: Form,
  },

  methods: {
    ...mapActions("ManualAddress", [
      "getAccountArr",
      "getAccountsArr",
    ]),
    ...mapMutations("ManualAddress", ["setInfoAddedAddress"]),
    accountArr() {
      let data = {
        city: this.SELECTED_CITY,
        street: this.SELECTED_STREET,
        arg_building_num: this.arg_building_num,
        arg_building_letter: this.arg_building_letter,
        arg_corpus_num: this.arg_corpus_num,
        arg_corpus_letter: this.arg_corpus_letter,
        arg_flat_num: this.arg_flat_num,
        arg_flat_letter: this.arg_flat_letter,
      };
      this.getAccountArr(data);
    },
    addInfo() {
      this.accountArr();
      this.$router.push({name: "AddLs",});
    }
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
