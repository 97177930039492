<template>
  <van-row justify="center" class="page-wrapper">
    <template>
      <van-col span="24" class="itserve-center flex-display-fix">
        <account
            :account="account"
            :show-balance="true"
            class="round-shadow-btn p-25 pb-25 container_width mt-15"
        />
      </van-col>
      <van-col
          span="24"
          class="
        itserve-center
        round-shadow-btn
        flex-display-fix
        container_width
        mt-15
      "
      >
<!--        <info-message class="apply-loader flex-display-fix write-ticket-message"-->
<!--        >{{ $t("pay.inDevelopment") }}-->
<!--        </info-message>-->

        <itserve-input
            v-model.number="amount"
            type="number"
            inputmode="decimal"
            :placeholder="$t('pay.payAmt')"
            name="amount"
            class="payment-amount apply-loader"
        />
<!--        <template v-if="!getPhone && stripePayment === 1" >-->
          <template v-if="!getPhone" >
          <div class="itserve-center">
            <itserve-button
                type="primary"
                :value="$t('pay.pay')"
                class="mt-15 apply-loader"
                @click="showPopup = true;"
            />
          </div>
          <add-phone v-if="showPopup"></add-phone>
        </template>
<!--        <template v-else-if="!getEmail && stripePayment === 0" >-->
<!--          <div class="itserve-center">-->
<!--            <itserve-button-->
<!--              type="primary"-->
<!--              :value="$t('pay.pay')"-->
<!--              class="mt-15 apply-loader"-->
<!--              @click="showPopup = true;"-->
<!--            />-->
<!--          </div>-->
<!--          <add-email v-if="showPopup"></add-email>-->
<!--        </template>-->
        <template v-else>
          <div class="itserve-center">
            <itserve-button
                type="primary"
                :value="$t('pay.pay')"
                class="mt-15 apply-loader"
                @click="runPayment"
            />
          </div>
        </template>
<!--                <itserve-route-->
<!--                  type="primary"-->
<!--                  :buttonName="$t('pay.addCard')"-->
<!--                  class="mt-15"-->
<!--                  route-name="AddCard"-->
<!--                />-->
                <itserve-route
                  route-name="Prices"
                  :buttonName="$t('buttons.prices')"
                  type="wide"
                  class="nav-btn mt-15"
                  :icon="true"
                  icon-color="#1AAD62"
                >
                  <prices-icon />
                </itserve-route>
        <div class="place-content-center">
          <info-message class="pay-info-message apply-loader">
            {{ $t("pay.debtNotification") }}
          </info-message>
        </div>

                <div class="itserve-center">
                  <itserve-button
                    type="primary"
                    :value="$t('pay.receipt')"
                    class="apply-loader"
                  ></itserve-button>
                </div>
        <simple-dialog
            v-model="showDialog"
            :success="true"
            :title="this.$t('pay.fees')"
            @change="runPayment"
        />
      </van-col>
    </template>
  </van-row>
</template>
<script>
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/money-bill-wave";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/file-invoice-dollar";
import Account from "../../../components/helpers/Account";
import ItserveRoute from "@/components/content/ItserveRoute";
import PricesIcon from "@/components/icons/prices-icon";
import SimpleDialog from "@/components/content/SimpleDialog";
import addPhone from "../../../components/popup/addPhone";
// import addEmail from "../../../components/popup/addEmail";
// import Badge from "vant/lib/badge/index";
export default {
  name: "Pay",
  components: {
    SimpleDialog,
    PricesIcon,
    ItserveRoute,
    Account,
    InfoMessage,
    ItserveButton,
    ItserveInput,
    addPhone,
    // addEmail,
    // VanBadge: Badge,
  },
  data() {
    return {
      showDialog: false,
      showPopup: false,
      stripeSubscriptionData: {
        loading: false,
        mode: 'subscription',
        balance: this.amount,
        email: this.$store.state.Settings.settings.email,
        user_id: this.$store.state.Auth.user_id,
        app_id: this.$store.state.Auth.nativeData.id,
        ls: this.$route.params.account
      },
    };
  },
  computed: {
    cards() {
      return this.$store.state.Payment.cards;
    },
    amount: {
      get() {
        return this.$store.state.Payment.paymentData.amount;
      },
      set(amount) {
        this.$store.commit("Payment/UPDATE_AMOUNT", amount);
      },
    },
    account() {
      return this.$store.state.Auth.account;
    },
    getPhone() {
      return this.$store.state.Auth.phoneNumber;
    },
    stripePayment() {
      return this.$store.state.Auth.stripe_payment;
    },
    getEmail() {
      return this.$store.state.Settings.checkEmail;
    },
  },
  watch: {
    paymentUrl(paymentUrl) {
      window.location.href = paymentUrl;
    },
  },
  created() {},
  methods: {
    async preparePaymentPage() {
      await this.$store.dispatch(
          "Auth/getUserAccount",
          this.$route.params.account
      );
      await this.$store.dispatch("Payment/getCardsList");

      this.$store.commit("Payment/SET_USER_LS", this.$route.params.account);
    },
    showNotification() {
      if (this.cards.length) {
        this.showDialog = true;
      } else {
        this.runPayment();
      }
    },
    async runPayment() {
      this.stripeSubscriptionData.balance = this.amount;
      this.stripeSubscriptionData.email = this.$store.state.Settings.settings.email;
      // if (this.amount < 50 || this.amount > 900 || this.stripePayment === 1){
        await this.$router.push({
          name: "EasyPayPayment",
          params: this.$route.params,
        });
      // }
      // else{
      //   let url = await this.$store.dispatch("Payment/getStripeUrl", this.stripeSubscriptionData);
      //   this.nativeSendEvent("url", url);
      //   await this.$router.push({ name: "Home" });
      // }
    },
  },
};
</script>
<style>
.pay-info-message .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
.button-badge {
  margin-bottom: 15px;
  max-width: 337px;
  display: inline-block;
  width: 100%;
  text-align: right;
  position: relative;
}
.button-badge .van-badge {
  padding: 5px 10px;
  font-size: 14px;
  color: #0b0b0b;
  border-radius: 4px;
}
.badge-triangle {
  width: 10px;
  height: 10px;
  background-color: #f6f6f6;
  position: absolute;
  right: 40px;
  transform: rotate(45deg);
  top: 23px;
}
</style>
